import type { DynamicSessionModel } from '@wix/thunderbolt-symbols'

const EVENT_NAME = 'getAccessTokens'
const FETCH_TIMEOUT = 5000

// This event is dispatched when thunderbolt app loaded and whenever we need to refresh the access tokens
// The actual fetching is done in the main-head in the handleAccessTokens.ts file
// This solution has security vulnerabilities and we should consider better alternatives
export const fetchDynamicModel = (fetchArgs: RequestInit = {}): Promise<DynamicSessionModel> => {
	return new Promise(function (resolve, reject) {
		const timeoutId = setTimeout(() => {
			reject(new Error('Timeout occurred while waiting for access tokens response.'))
		}, FETCH_TIMEOUT)

		dispatchEvent(
			new CustomEvent(EVENT_NAME, {
				detail: {
					fetchArgs,
					handleAccessTokens: (args: DynamicSessionModel) => {
						clearTimeout(timeoutId)
						resolve(args)
					},
				},
			})
		)
	})
}
